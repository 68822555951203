@import "../node_modules/bootstrap/scss/bootstrap.scss";
@font-face {
  font-family: "Celine-Peach";
  src: url("./assets/fonts/Celine\ Peach\ Sans.ttf") format("truetype");
}

body {
  background-color: #151515;
  color: white;
  font-family: "Celine-Peach", sans-serif;
}

.word {
  height: 200px;
  width: 300px;
  position: absolute;
  left: 0;
  padding-left: 30px;
  font-size: 1.5rem;
  opacity: 0;
  transition: opacity 0.5s;
  &:hover,
  &:active {
    opacity: 1;
  }
}

.w-2 {
  left: 300px;
  top: 450px;
}

.w-3 {
  left: 70%;
  top: 200px;
}

.w-4 {
  left: 70%;
  top: 500px;
}

.w-5 {
  left: 300px;
  top: 600px;
  padding-left: 30%;
}

.w-6 {
  left: 300px;
  top: 50px;
  padding-left: 30%;
}

.w-7 {
  left: 300px;
  top: 650px;
}

.w-8 {
  left: 80%;
  top: 650px;
}

.w-9 {
  left: 250px;
  top: 200px;
  padding-left: 5%;
}

.w-10 {
  left: 73%;
  top: 375px;
}

.w-11 {
  left: 600px;
  top: 500px;
}

.w-12 {
  left: 600px;
  top: 135px;
}

.disabled {
  color: #5f5c5c;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
  text-decoration: none;
}

.logo-img {
  height: 100px;
}

.logo-text {
  letter-spacing: 3px;
  font-size: 16px;
}

.logo-container {
  height: 75%;
}

// Media Query
@media screen and (min-device-width: 1024px) and (max-device-width: 1024px) {
    .word {
        height: 200px;
        width: 200px;
        position: absolute;
        left: 0;
        padding-left: 30px;
        font-size: 1.5rem;
        opacity: 0;
        transition: opacity 0.5s;
      }
    
      .w-1 {
        left: 50%;
        top: 700px;
      }
    
      .w-2 {
        left: 100px;
        top: 300px;
      }
    
      .w-3 {
        left: 50%;
        top: 200px;
      }
  
      .w-4 {
        left: 40%;
        top: 750px;
      }
    
      .w-5 {
        left: 150px;
        top: 800px;
        padding-left: 10%;
      }
    
      .w-6 {
        left: 250px;
        top: 170px;
        padding-left: 10%;
      }
    
      .w-7 {
        left: 200px;
        top: 750px;
      }
    
      .w-9{
        left: 200px;
        top: 200px;
        padding-left: 3%;
      }
    
      .w-10{
        left: 50%;
        top: 375px;
      }
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 768px) {
    .word {
      height: 200px;
      width: 200px;
      position: absolute;
      left: 0;
      padding-left: 30px;
      font-size: 1.5rem;
      opacity: 0;
      transition: opacity 0.5s;
    }
  
    .w-1 {
      left: 50%;
      top: 550px;
    }
  
    .w-2 {
      left: 100px;
      top: 300px;
    }
  
    .w-3 {
      left: 50%;
      top: 200px;
    }

    .w-4 {
        left: 40%;
        top: 600px;
    }
  
    .w-5 {
      left: 150px;
      top: 600px;
      padding-left: 10%;
    }
  
    .w-6 {
      left: 150px;
      top: 170px;
      padding-left: 10%;
    }
  
    .w-7 {
        left: 200px;
        top: 650px;
    }
  
    .w-9{
      left: 200px;
      top: 200px;
      padding-left: 3%;
    }
  
    .w-10{
      left: 50%;
      top: 375px;
    }
  }

@media only screen and (max-width: 700px) {
  .logo-img {
    height: 50px;
  }
  .logo-text {
    letter-spacing: 0px;
    font-size: 12px;
  }
  .logo-container {
    height: 50%;
  }
}

@media only screen and (max-width: 414px) {
  .word {
    height: 200px;
    width: 200px;
    position: absolute;
    left: 0;
    padding-left: 30px;
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .w-1 {
    left: 50%;
    top: 550px;
  }

  .w-2 {
    left: 100px;
    top: 450px;
  }

  .w-3 {
    left: 50%;
    top: 200px;
  }

  .w-5 {
    left: 150px;
    top: 600px;
    padding-left: 10%;
  }

  .w-6 {
    left: 150px;
    top: 170px;
    padding-left: 10%;
  }

  .w-7 {
    left: 200px;
    top: 500px;
  }

  .w-9{
    left: 200px;
    top: 200px;
    padding-left: 3%;
  }

  .w-10{
    left: 50%;
    top: 375px;
  }
}

@media only screen and (max-width: 700px) {
  .logo-img {
    height: 50px;
  }
  .logo-text {
    letter-spacing: 0px;
    font-size: 12px;
  }
  .logo-container {
    height: 50%;
  }
}

@media only screen and (max-width: 414px) {
  .word {
    height: 200px;
    width: 200px;
    position: absolute;
    left: 0;
    padding-left: 30px;
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .w-1 {
    left: 50%;
    top: 550px;
  }

  .w-2 {
    left: 100px;
    top: 450px;
  }

  .w-3 {
    left: 50%;
    top: 200px;
  }

  .w-5 {
    left: 150px;
    top: 600px;
    padding-left: 10%;
  }

  .w-6 {
    left: 150px;
    top: 170px;
    padding-left: 10%;
  }

  .w-7 {
    left: 200px;
    top: 500px;
  }

  .w-9{
    left: 200px;
    top: 200px;
    padding-left: 3%;
  }

  .w-10{
    left: 50%;
    top: 375px;
  }
}

@media screen and (min-device-width: 375px) and (max-device-width: 375px) {
  .word {
    height: 200px;
    width: 200px;
    position: absolute;
    left: 0;
    padding-left: 30px;
    font-size: 1.5rem;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .w-1 {
    left: 10%;
    top: 500px;
  }

  .w-2 {
    left: 100px;
    top: 400px;
  }

  .w-3 {
    left: 20%;
    top: 250px;
  }

  .w-4 {
    left: 20%;
    top: 400px;
  }

  .w-5 {
    left: 150px;
    top: 400px;
  }

  .w-6 {
    left: 150px;
    top: 170px;
    padding-left: 10%;
  }

  .w-7 {
    left: 100px;
    top: 650px;
  }

  .w-9{
    left: 175px;
    top: 225px;
    padding-left: 3%;
  }

  .w-10{
    left: 10%;
    top: 375px;
  }
}

